<template>
  <div class="wrapper" style="">
    <div class="jackpot_list">
      <div v-for="jack in jackpots" :key="jack.id">
        <JackpotCard :jackpot="jack" />
      </div>
      <div v-if="loading" class="load">
        <LoadingSpinner />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchJackpot } from "../../actions/jackpot";
import LoadingSpinner from "../../components/ui/LoadingSpinner.vue";
import JackpotCard from "./components/JackpotCard.vue";

export default {
  name: "Jackpot",
  data() {
    return {
      loading: false,
      jackpots: [],
    };
  },
  components: {
    JackpotCard,
    LoadingSpinner,
  },
  mounted() {
    this.fetchAllJackpot();
  },
  methods: {
    async fetchAllJackpot() {
      this.loading = true;
      const jackpot = await fetchJackpot();
      if (jackpot) {
        this.jackpots = jackpot;
      } else {
        this.jackpots = [];
        console.log("Error fetching jackpot");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 20px;
  /* background-color: red; */
  background-color: var(--background-color);
}
.jackpot_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.load {
  padding-top: 3rem;
}
</style>
