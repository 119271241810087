import Cookies from "js-cookie";

export function getAuth() {
  var p = Cookies.get("a");

  if (p) {
    if (p === "undefined") {
      return false;
    }

    try {
      var data = p.trim();
      if (data.length > 0) {
        return data;
      }
    } catch (err) {
      return false;
    }
  }

  return false;
}
