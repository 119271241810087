import axios from "axios";
import { getAuth } from "../utils/auth";

const jackpot = axios.create({
  baseURL: process.env.VUE_APP_BASE_JACKPOT_URL,
});

jackpot.defaults.headers.post["Content-Type"] = "application/json";
jackpot.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

jackpot.interceptors.request.use(
  (config) => {
    const apiKey = getAuth();
    if (apiKey) {
      config.headers["api-key"] = apiKey || "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

jackpot.defaults.method = "get";

export default jackpot;
