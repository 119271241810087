<template>
  <div class="jackpot">
    <div class="card_img">
      <img src="/img/jackpotcardbg.png" alt="jackpot" />
      <div class="bonus_card">
        <img src="/img/discount200.png" alt="jackpot" />
        <span>on Deposit up to</span>
        <img src="/img/discountxof.png" alt="jackpot" />
      </div>
    </div>
    <div class="cardd">
      <div class="inner_card">
        <h4 class="card_title">{{ jackpot.name }}</h4>
      </div>
      <router-link class="btn" :to="`/play-jackpot/${jackpot.id}`"
        ><ChopbetButton>Play Jackpot</ChopbetButton></router-link
      >
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";

export default {
  name: "JackpotCard",
  props: {
    ticket: {
      type: String,
      default: "0.50 XOF",
    },
    time: {
      type: String,
      default: "21:40",
    },
    date: {
      type: String,
      default: "Saturday, 07/09/24",
    },
    title: {
      type: String,
      default: "Predict 12 Matches to Win 250,000 XOF",
    },
    jackpot: {
      type: Object,
      default: null,
    },
  },
  components: {
    ChopbetButton,
    // TicketIcon,
    // ClockIcon,
  },
};
</script>

<style scoped>
.jackpot {
  margin-bottom: 1rem;
}
.btn {
  padding: 14px 0;
  width: 100%;
  font-size: 10px;
}
h4 {
  color: var(--Light-Mode-Base-Base-900, #0e0d0d);

  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}
.cardd {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  /* color: #0e0d0d; */
  gap: 10px;
  background-color: var(--bg-color);
  /* background-color: red; */
  color: var(--text-color);
  border-radius: 0 0 5px 5px;
  padding-bottom: 0px;
}
.card_title {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.14px;
}
.card_items {
  display: flex;
  font-size: 10px;
  align-items: center;
  gap: 5px;
  p {
    color: var(--Light-Mode-Base-Base-600, #545151);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
    margin-bottom: 9px;
  }
  svg {
    margin-top: -11px;
  }
}
p {
  margin: 0;
}
.inner_card {
  display: flex;
  flex-direction: column;
}
.card_img {
  height: 119px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}
.bonus_card {
  width: 100%;
  height: 100%;
  padding: 16px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  top: 0;
  border-radius: 5px 5px 0 0;
}
.bonus_card img:first-child {
  width: 100px;
  height: 30px;
  object-fit: fill;
}
.bonus_card img:last-child {
  width: 120px;
  height: 30px;
  object-fit: fill;
}
.bonus_card span {
  color: #efff6d;
}
</style>
