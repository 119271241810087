import jackpot from "../services/jackpot";

export const fetchJackpot = async () => {
  const path = "/jackpot/categories?stake_type=-1";
  try {
    const response = await jackpot.get(path);
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred";
    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;
      if ([401, 428].includes(status)) {
        errorMessage = `${this.$t("sessionExpired")}`;
        this.logout();
        return;
      }
    }
    this.setError(errorMessage);
    // throw new Error(errorMessage);s
  }
};

export const fetchJackpotGames = async (param) => {
  const path = `/jackpot/${param}`;
  try {
    const response = await jackpot.get(path);
    return response.data;
  } catch (error) {
    let errorMessage = "An error occurred";
    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;
      if ([401, 428].includes(status)) {
        errorMessage = `${this.$t("sessionExpired")}`;
        this.logout();
        return;
      }
    }
    this.setError(errorMessage);
    // throw new Error(errorMessage);s
  }
};

export const placeJackpotBet = async (jackpot_id, payload) => {
  var path = `/jackpot/${jackpot_id}/bet`;
  // console.log(jackpot_id, payload, "-------jackpot_id, payload---");
  try {
    const response = await jackpot.post(path, JSON.stringify(payload));
    // console.log(response, "responseresponse");
    return response;
  } catch (error) {
    // let errorMessage = "An error occurred";
    if (error.response) {
      if ([401, 428].includes(status)) {
        // errorMessage = "Your session on this device has expired";
        this.logout();
        return;
      }
    }
    // this.setError(errorMessage);
    return { error: error.response.data.error_message };
  }
};
